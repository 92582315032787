.sidebar-logo {
  height: 64px;
  border-bottom: 1px solid #37475f;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #233044;
}
.sidebar-logo img {
  width: 60%;
}
.list-section{
  padding: 20px 25px;
  background: #2c3c53;
}
/* Sidebar menu css */
.scrollable-sidebar-container {
  height: calc(100vh - 65px);
  overflow-x: hidden;
  overflow-y: auto;
}
.scrollable-sidebar-container::-webkit-scrollbar {
  width: 5px;
}
.scrollable-sidebar-container::-webkit-scrollbar-thumb {
  background-color: #6b778b;
}
.sidebar-menu a span,
.sidebar-menu a span,
.sidebar-menu span {
  font-size: 14px;
}
.sidebar-menu > a,
.sidebar-dropdown {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
  color: #ddd;
  text-decoration: none;
  cursor: pointer;
  border-left: 4px solid transparent;
  transition: 0.2s;
}
.sidebar-menu > a:hover,
.sidebar-menu > a.active,
.sidebar-dropdown:hover,
.sidebar-dropdown.true {
  border-color: #6b778b;
}
.sidebar-dropdown > div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.dropdown-items {
  max-height: 0px;
  overflow: hidden;
  transition: 0.3s;
}
.dropdown-items.true {
  max-height: 2000px;
  opacity: 1;
}
.dropdown-items > a {
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  color: #dddddd;
  padding: 10px 20px 10px 48px;
}
.dropdown-indicator {
  height: 7px;
  width: 7px;
  background-color: transparent;
  border: 1px solid #ddd;
  border-radius: 50%;
  transition: 0.3s;
}
.dropdown-items > a:hover .dropdown-indicator,
.dropdown-items > a.active .dropdown-indicator {
  background-color: #afafaf;
}
.sidebar-menu a.active,
.sidebar-menu a:hover {
  background: #2c3c53;
}
.sidebar-dropdown > div > svg {
  transition: 0.3s;
}
.sidebar-dropdown.true > div > svg {
  transform: rotate(90deg);
}
