.product-informaiton-form {
  display: grid;
  grid-template-columns: 70px auto;
  grid-gap: 20px;
  align-items: center;
}
.product-informaiton-form span {
  font-size: 0.9rem;
}
input,
textarea {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  color: #666;
  width: 100%;
  outline: none;
}
.react-tag-input__input {
  border: none !important;
}
input:focus,
textarea:focus {
  border: 1px solid #377dff;
}
.css-1pahdxg-control {
  box-shadow: none !important;
}
.css-1okebmr-indicatorSeparator {
  display: none;
}
.css-14el2xx-placeholder {
  font-size: 0.9rem !important;
  color: #666 !important;
}

@media only screen and (min-width: 768px) {
  .product-informaiton-form {
    display: grid;
    grid-template-columns: 160px auto;
    grid-gap: 20px;
    align-items: center;
  }
  .product-informaiton-form span {
    font-size: 1rem;
  }

  .css-14el2xx-placeholder {
    font-size: 1rem !important;
    color: #666 !important;
  }
}
