/* Bootstrap need to remove */

.modal-wrapper {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  background-color: #00000060 !important;
  width: 100vw !important;
  height: 100vh !important;
  z-index: 1000 !important;
}
.modal-wrapper.false {
  display: none !important;
}
.modal-container {
  background-color: #fff !important;
  border-radius: 5px !important;
  animation: modalAnimation 0.5s forwards !important;
  width: 100% !important;
}

@keyframes modalAnimation {
  0% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
  }
}
.modal-scrollable-container {
  height: -moz-fit-content !important;
  height: fit-content !important;
  max-height: calc(100vh - 80px) !important;
  overflow-y: auto !important;
  padding: 10px 20px !important;
}
.modal-header {
  display: flex !important;
  justify-content: space-between !important;
  padding: 12px 20px !important;
  border-bottom: 1px solid #ddd !important;
  align-items: center !important;
}
.modal-title {
  font-size: 17px !important;
  font-weight: 500 !important;
}
svg.modal-close {
  font-size: 20px !important;
  cursor: pointer !important;
}
.modal-scrollable-container {
  height: -moz-fit-content !important;
  height: fit-content !important;
  max-height: calc(100vh - 150px) !important;
  overflow-y: auto !important;
  padding: 10px 20px !important;
}
.modal-footer {
  border-top: 1px solid #ddd !important;
  padding: 8px 20px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
  gap: 15px !important;
}

/* .modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #00000060;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
}
.modal-wrapper.false {
  display: none;
}
.modal-container {
  background-color: #fff;
  border-radius: 5px;
  animation: modalAnimation 0.5s forwards;
}
@keyframes modalAnimation {
  0% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
  }
}
.modal-scrollable-container {
  height: -moz-fit-content;
  height: fit-content;
  max-height: calc(100vh - 80px);
  overflow-y: auto;
  padding: 10px 20px;
}
.modal-header {
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
  border-bottom: 1px solid #ddd;
  align-items: center;
}
.modal-title {
  font-size: 17px;
  font-weight: 500;
}
svg.modal-close {
  font-size: 20px;
  cursor: pointer;
}
.modal-scrollable-container {
  height: -moz-fit-content;
  height: fit-content;
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  padding: 10px 20px;
}
.modal-footer {
  border-top: 1px solid #ddd;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
} */
