.revenue-statistics {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  background-color: #efefef33;
  margin: 5px 0px;
}
.revenue-statistics > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  border: 1px dashed #ddd;
  border-right: none;
}
.revenue-statistics > div:last-child {
  border-right: 1px dashed #ddd;
}

.revenue-sts-value {
  font-size: 16px;
  font-weight: 500;
  color: #444;
}

.revenue-sts-label {
  color: #7c7c7c;
  font-size: 13px;
}
@media (max-width: 550px) {
  .revenue-statistics {
    grid-template-columns: repeat(2, 1fr);
  }
}
