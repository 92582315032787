.dashboard-revenue-review-container {
  display: grid;
  grid-template-columns: 3.5fr 1.5fr;
  gap: 20px;
}
.dashboard-first-widget {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.d-pie-chart-widget {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 5px;
  grid-gap: 20px;
}
.dashboard-second-widget {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-top: 20px;
}

@media (max-width: 1200px) {
  .dashboard-revenue-review-container {
    grid-template-columns: 1fr;
  }
}
