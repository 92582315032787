.paginationContainer {
  /* max-width: 900px; */
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: 10px;
  /* align-items: center; */
  /* margin-bottom: 8rem; */
}

.paginationContainer .paginationSelect {
  /* margin-bottom: 33px !important; */
  /* margin-left: -15px; */
  margin-bottom: 1rem!important;
}

.pageClassName {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center!important;
  align-items: center;
}

.prevBtn,
.nextBtn {
  background-color: #75a1f2;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: flex;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  /* color: #fff !important; */
  transition: all 0.1s;
  color: #fff;
}

.prevBtn {
  margin-right: 10px !important;
}
.nextBtn {
  margin-left: 10px !important;
}
.prevBtn:hover {
  background-color: #377dff;
  color: #fff;
}
.nextBtn:hover {
  background-color: #377dff;
  color: #fff;
}

.activeBtn {
  background-color: #377dff;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff !important;
}

@media only screen and (min-width: 768px) {
  .paginationContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* padding-right: 2rem; */
  }

  .paginationContainer .paginationSelect {
    margin-right: 3rem;
    /* margin-top: 12px; */
    margin-bottom: 0;
  }

  .activeBtn {
    color: #fff !important;
  }
}
