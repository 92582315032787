.pos-manager {
  display: grid;
  grid-template-columns: 1fr 1.3fr;
  grid-gap: 20px;
}

@media (max-width: 1280px) {
  .pos-manager {
    grid-template-columns: 1fr;
  }
}
