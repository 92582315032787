button.table-pay-btn {
  border: none;
  display: flex;
  align-items: center;
  gap: 5px;
  color: white;
  background: linear-gradient(52deg, #4782da, #527bb7);
  padding: 8px 12px;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
}

button.table-pay-btn::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.table-pay-btn:active::after {
  background-color: #ffffff36;
}
