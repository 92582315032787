.hs-single-slider {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
}
.hs-slider-header {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.hs-slider-hide {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 1px solid #ddd;
  font-size: 12px;
  height: 25px;
  width: 70px;
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
.hs-slider-hide:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-color: transparent transparent transparent #ddd;
  border-style: solid;
  border-width: 1px;
  transform: skewX(-15deg) translateX(72px);
}
.hs-slider-hide:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-color: transparent transparent transparent #ddd;
  border-style: solid;
  border-width: 1px;
  transform: skewX(15deg) translateX(-3px);
}
.hs-slider-hide svg {
  font-size: 16px;
}
.hs-slider-title {
  font-size: 20px;
}
button.hs-slider-delete-btn {
  border: none;
  background-color: #ec6161;
  color: white;
  padding: 6px 16px;
  border-radius: 2px;
}
.hs-slider-preview {
  height: 300px;
  background-color: #ddd;
  margin: 10px 0;
}
.hs-slider-fields-container {
  display: grid;
  grid-template-columns: 300px auto;
  align-items: center;
  gap: 17px;
}
.hs-slider-fields-container label {
  font-size: 16px;
}
