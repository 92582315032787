.wrapper {
  border: 1px solid #ccc;
  margin-top: 10px;
}
.editor {
  border: 1px solid #ccc;
  margin-top: 10px;
  min-height: 200px;
  margin: auto !important;
}

.table-header {
  /* background-color: #0080a7; */
  font-weight: bold;
  font-size: 1.1em;
  text-wrap: normal;
  padding: 5px;
}

.public-DraftStyleDefault-block {
  min-height: auto !important;
  margin: auto !important;
}

#purchase_date {
  height: 38px;
}
#reference_no {
  height: 38px;
}
