.count-boxes {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.count-boxes .single-widget {
  padding: 1rem;
  box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
  transition: 0.3s;
}
.count-boxes .single-widget:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 10px rgb(30 32 37 / 12%);
}
.count-box-title {
  color: #878a99;
  font-weight: 500;
  font-size: 15px;
  text-transform: uppercase;
}

.count-box-icon {
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
.count-box-icon svg {
  font-size: 24px;
}
.count-box-value {
  font-size: 25px;
  padding: 15px 0 10px;
}
.count-box-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.count-box-footer p {
  font-size: 12px;
  color: #878a99;
}
.percentage-show {
  display: flex;
  align-items: center;
  gap: 5px;
}
.percentage-show svg {
  font-size: 18px;
}

.count-boxes > div:nth-child(1) .count-box-icon {
  background-color: rgba(247, 184, 75, 0.18);
}
.count-boxes > div:nth-child(1) .count-box-icon svg {
  color: orange;
}
.count-boxes > div:nth-child(2) .count-box-icon {
  background-color: rgb(75 191 247 / 18%);
}
.count-boxes > div:nth-child(2) .count-box-icon svg {
  color: rgb(0 91 135 / 58%);
}
.count-boxes > div:nth-child(3) .count-box-icon {
  background-color: rgba(247, 184, 75, 0.18);
}
.count-boxes > div:nth-child(3) .count-box-icon svg {
  color: orange;
}
.count-boxes > div:nth-child(4) .count-box-icon {
  background-color: rgba(64, 81, 137, 0.18);
}
.count-boxes > div:nth-child(4) .count-box-icon svg {
  color: navy;
}

@media (max-width: 1200px) {
  .count-boxes {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 490px) {
  .count-boxes {
    grid-template-columns: repeat(1, 1fr);
  }
}
