.addDigitalTop {
  display: flex;
  justify-content: space-between;
  margin-right: 0;
  margin-bottom: 1rem;
}
.add_product_widget_container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  grid-row-gap: 1rem;
  margin-right: 0;
}
.add-product-single-widget {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 0 5px #ddd;
}
.widget-title {
  font-size: 16px;
  font-weight: 500;
  padding: 10px 20px;
  border-bottom: 1px solid #edeef4;
}
.widget-content-container {
  padding: 15px 20px 20px;
}
.add_product_widget_container > div {
  display: flex;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  gap: 20px;
}

@media only screen and (min-width: 1080px) {
  .add_product_widget_container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
    margin-right: 10%;
  }

  .addDigitalTop {
    display: flex;
    justify-content: space-between;
    margin-right: 10%;
  }
}
