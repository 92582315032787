.ap-single-content {
  display: grid;
  grid-template-columns: 160px auto;
  grid-gap: 20px;
}
.ap-single-content {
  display: grid;
  grid-template-columns: 70px auto;
  grid-gap: 20px;
  margin-bottom: 20px;
}
.ap-single-content span {
  font-size: 0.7rem;
}

.ap-single-content p {
  font-size: 0.9rem;
}
.ap-single-content:last-child {
  margin: 0;
}
.custom-browse {
  display: grid;
  grid-template-columns: 70px auto;
  border: 1px solid #ddd;
  border-radius: 3px;
  font-size: 0.7rem;
  cursor: pointer;
}
.custom-browse > div:first-child {
  background-color: #ddd;
  padding: 10px;
  text-align: center;
  color: #555;
}
.custom-browse > div:last-child {
  padding: 10px;
  color: #777;
}
.helper-text {
  font-size: 12px;
  color: #666;
}

@media only screen and (min-width: 768px) {
  .ap-single-content {
    display: grid;
    grid-template-columns: 160px auto;
    grid-gap: 20px;
    margin-bottom: 20px;
  }
  .custom-browse {
    display: grid;
    grid-template-columns: 110px auto;
    border: 1px solid #ddd;
    border-radius: 3px;
    cursor: pointer;
    font-size: 1rem;
  }

  .ap-single-content span {
    font-size: 0.8rem;
  }

  .ap-single-content p {
    font-size: 1rem;
  }
}
