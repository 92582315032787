.pos-searching-prod {
  background-color: #fff;
}
.pos-searching-header {
  border-bottom: 1px solid #ddd;
  padding: 20px;
}
input.prod-search-input {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 15px;
}
.pos-searching-filter-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
}
.pos-searching-products-container {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 7px;
  max-height: calc(100vh - 238px);
  overflow-y: auto;
}
.single-psp {
  border: 1px solid #efefef;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
}
.single-psp-img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}
.single-psp img {
  max-width: 100%;
  max-height: 100%;
}
.single-psp-title {
  font-size: 12px;
  color: #555;
  padding: 5px;
  background-color: #f1f1f1;
}
span.single-psp-price {
  background-color: #111;
  color: #fff;
  padding: 1px 10px;
  font-size: 10px;
  position: absolute;
  top: 0;
  left: 0;
}
