.dragContainer {
  position: relative;
  /* display: flex;
  justify-content: center;
  align-items: center; */

  display: grid;
  grid-template-columns: auto;
  width: 100%;
  height: 64vh;
}

.dragContainer input {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.imageContainer {
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  /* width: 20%; */
  width: 100%;
  height: 100%;
  cursor: pointer;

  overflow-y: scroll;
}
.imageContainer img {
  height: 50px;
  width: 50px;
  margin-right: 1rem;
  border-radius: 10px;
  margin-bottom: 0.7rem;
}
.singleImgContainer {
  position: relative;
}
.imageContainer small {
  position: absolute;
  top: 0;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.562);
  top: 0;
  left: 0.4rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 3px;
  font-weight: 600;
  opacity: 0;
  transition: all 0.3s;
}
.singleImgContainer:hover small {
  opacity: 1;
}

.imageInput {
  display: flex;
  justify-content: center;
  align-items: center;
}
.imageInput h2 {
  font-size: 1rem;
}

.singleImgContainer {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
}

@media only screen and (min-width: 1040px) {
  .imageContainer {
    height: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 15%;
    height: 100%;

    overflow-y: scroll;
  }
  .imageContainer img {
    height: 140px;
    width: 140px;
    margin-right: 1rem;
    border-radius: 10px;
    margin-bottom: 0.7rem;
    cursor: pointer;
  }
  .imageInput h2 {
    font-size: 2rem;
  }
}
