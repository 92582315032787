@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #8a8a8a;
  border-radius: 8px;
}
body {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}
input,
textarea,
select {
  border: 1px solid #e1e1e1;
  padding: 10px 15px;
  border-radius: 3px;
  font-size: 16px;
  color: #666;
  transition: 0.3s;
  outline: none;
  width: 100%;
}
input:hover,
textarea:hover,
select:hover {
  border-color: #555;
}

input:focus,
select:focus,
textarea:focus {
  border-color: #4782da;
}
button.date-filter-btn {
  border: 1px solid #e1e1e1;
  background-color: #fff;
  font-size: 16px;
  color: #666;
  padding: 10px 15px;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  width: 200px;
}
.date-filter-dropdown {
  position: absolute;
  background-color: #fff;
  z-index: 100;
  width: 100%;
  top: 0;
  left: 0;
  box-shadow: 0 2px 5px #979797e8;
  border-radius: 3px;
}

.color-main {
  color: #4782da;
}
.color-secondary {
  color: #233044;
}
.bg-main {
  background-color: #4782da;
}
.bg-secondary {
  background-color: #233044;
}
.gradient {
  background: linear-gradient(90deg, #6645ce, #886be2);
}
.single-widget {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: rgb(50 50 93 / 2%) 0px 2px 5px -1px,
    rgb(0 0 0 / 5%) 0px 1px 3px -1px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgba(0, 0, 0, 0.87);
}
.single-widget:hover {
  box-shadow: rgb(50 50 93 / 15%) 0px 2px 5px -1px,
    rgb(0 0 0 / 20%) 0px 1px 3px -1px;
}
.widget-title {
  font-size: 16px;
  font-weight: 500;
  padding: 12px 20px;
  border-bottom: 1px solid #edeef4;
}
.widget-container {
  padding: 10px 20px 20px;
}

.text-center {
  text-align: center;
}

.vartical-inputs label {
  display: block;
  margin: 9px 0;
}

.btns-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  gap: 10px;
}

button.add-btn {
  height: fit-content;
  border: none;
  background-color: #4782da;
  color: #fff;
  padding: 11px 22px;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.3s;
  font-size: 16px;
}

button.add-btn:hover {
  background-color: #3675d3;
}
button.draft-btn {
  border: none;
  background-color: transparent;
  color: #4782da;
  border: 1px solid #4782da;
  padding: 11px 22px;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.3s;
  font-size: 16px;
}

button.draft-btn:hover {
  background-color: #fff;
}

/* Custom file browse input */
.custom-browse {
  display: grid;
  grid-template-columns: 100px auto;
  border: 1px solid #e1e1e1;
  border-radius: 3px;
  cursor: pointer;
}
.custom-browse > div:first-child {
  background-color: #ebebeb;
  padding: 10px;
  text-align: center;
  color: #555;
}
.custom-browse > div:last-child {
  padding: 10px;
  color: #777;
}
/* Custom file browse input */

/* Commmon css for table */
.table-top-col-header {
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
}
.table-top-header {
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
}
.table-title {
  font-size: 16px;
  font-weight: 500;
}
.table-filters {
  display: flex;
  align-items: center;
  gap: 12px;
}
.table-header {
  padding: 12px 0;
  display: grid;
  border-bottom: 1px solid #e9e9e9;
  font-weight: 500;
  grid-gap: 12px;
}
.table-row {
  display: grid;
  grid-gap: 12px;
  border-bottom: 1px solid #e9e9e9;
  align-items: center;
  padding: 5px 0;
  min-height: 47px;
}
.action-column {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.row-extra {
  margin-left: 50px;
}
.row-extra-row {
  display: grid;
  grid-template-columns: 135px auto;
  grid-gap: 10px;
  border-bottom: 1px solid #e5e5e5;
  padding: 10px;
}
.row-extra-row > div:first-child {
  font-weight: 500;
  border-right: 1px solid #e5e5e5;
}
.table-img {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.table-extra-img {
  height: 100px;
  width: 100px;
}
.table-img img,
.table-extra-img img {
  max-width: 100%;
  max-height: 100%;
}

button.table-btn {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font-size: 13px;
}
.tb-edit {
  background-color: #80a8f3;
  color: #e0e6f0;
  transition: 0.3s;
}
.tb-edit:hover {
  background-color: #377dff;
  color: #ddd;
}
.tb-copy {
  background-color: #55d9e7;
  color: #e0e6f0;
  transition: 0.3s;
}
.tb-copy:hover {
  background-color: #19c5d8;
  color: #ddd;
}
.tb-delete {
  background-color: #f88490;
  color: #e0e6f0;
  transition: 0.3s;
}
.tb-delete:hover {
  background-color: #dc3545;
  color: #ddd;
}

input.table-search-input {
  outline: none;
  border: 1px solid #ddd;
  height: 38px;
  width: 210px;
  padding: 0 20px;
  font-size: 15px;
  border-radius: 5px;
  color: #555;
  transition: 0.3s;
}
input.table-search-input:focus {
  width: 280px;
  border-color: #4788ff;
}
.responsive-table-container {
  overflow-y: scroll;
}

.single-widget.table {
  min-width: 900px;
  min-height: 400px;
}

/* Common css for table */
/* Table pagination css */
.table-pagination-container {
  border-top: 1px solid #ddd;
  padding: 15px 20px;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.table-pagination-right {
  display: flex;
  gap: 10px;
  align-items: center;
}

.table-pagination {
  display: flex;
  align-items: center;
  gap: 8px;
}

.table-pagination button {
  font-size: 28px;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #555;
  transition: 0.3s;
}

.table-pagination button:hover {
  background-color: #4788ff;
  color: #ddd;
}

.table-pagination-container p {
  font-size: 14px;
}
.disabled-btn {
  color: #bbb !important;
  pointer-events: none;
}
.table-pagination-right select {
  border: 1px solid #ddd;
  padding: 5px 15px;
  border-radius: 2px;
  color: #333;
  font-size: 14px;
}
.table-pagination-right select:focus {
  outline: none;
  border: 1px solid #999;
}

/* Table pagination css */

/* React select override */
/* .css-b62m3t-container > div {
  border: 1px solid #e1e1e1 !important;
} */
.css-b62m3t-container > div:hover {
  border-color: #555 !important;
}
.css-1pahdxg-control {
  box-shadow: none !important;
}
.css-1okebmr-indicatorSeparator {
  display: none;
}
.css-b62m3t-container {
  min-width: 135px;
}
.css-14el2xx-placeholder {
  font-size: 15px !important;
  color: #555 !important;
}
/* React select override */

/* Draft box Text area override */
.public-DraftStyleDefault-block {
  min-height: 144px;
}
/*  Draft box Text area override */
.top-section{
  padding:15px 20px
}
.ml-1{
  margin-left: 1%;
}

/* Checkbox design start*/
/* Customize the label (the container) */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 22px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* Checkbox design end*/

