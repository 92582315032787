button.table-filter-btn {
  border: 1px solid #dbdbdb;
  border-left: none;
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
button.ripple-button.table-export-btn {
  width: fit-content;
  height: 38px;
}
